.responsible-gaming-ctn {
  margin-right: 18px;
  margin-left: 18px;
  font-size: 16px;
  text-align: justify;
  background-color: var(--ion-card-background);

  @media screen and (max-width: 920px) {
    margin: 10px;
    font-size: 14px;
  }
  div, p, span {
    color: var(--text);
    font-family: 'Montserrat';
  }

  .data-ctn {
    margin: 2%;
    .rules-para-header {
      color: var(--ion-color-primary);
    }
  }
}
