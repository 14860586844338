.poker-ctn {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
}

@media only screen and (min-width: 992px) {
  .poker-ctn {
    height: calc(100vh - 78px);
  }
}

@media only screen and (max-width: 991px) {
  .poker-ctn {
    height: calc(100vh - 70px);
  }
}

@media only screen and (max-width: 575px) {
  .poker-ctn {
    height: calc(100vh - 50px);
  }
}

@media only screen and (max-height: 576px) and (orientation: landscape) {
  .poker-ctn {
    height: 100vh;
  }
}
