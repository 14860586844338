.punter-settings {
  .setting-row {
    margin-bottom: 20px;
  }

  .content-ctn {
    margin: 0 20px;
  }

  .toggle-col {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
  }

  .select-control {
    display: contents;
    margin-bottom: 20px;

    .MuiInputBase-root {
      margin-top: 5px;
      background-color: var(--ion-card-background-contrast);
      color: var(--ion-text-color-contrast);
      border-radius: 5px;
      display: block;
      height: 46px;
      width: 100%;
      padding: 0px;
    }

    .MuiSelect-select.MuiSelect-select {
      padding: 12px;
    }

    .MuiSelect-icon {
      color: var(--ion-text-color-contrast);
    }
  }

  .sub-title {
    width: 100%;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--ion-text-color-contrast);
  }

  .desc-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 721px) {
  .punter-settings {
    .content-ctn {
      margin: 0 10px;
    }
  }
}
