.transaction-details-ctn {
  width: 100%;
  min-height: 200px;
  .web-view {
    display: flex;
    padding: 12px 0px;
    padding-left: 0% !important;
    padding-right: 0% !important;

    .section {
      display: flex;
      width: 33%;
      flex-direction: column;
      flex-basis: 50%;
      .item {
        display: flex;
        flex-direction: row;
        width: 100%;
        font-size: 16px;
        padding: 8px 4px;
        .label {
          font-weight: bold;
          margin-right: 10px;
          min-width: 100px;
        }
      }
    }
  }

  .empty-msg {
    padding: 20px;
    display: flex;
    justify-content: center;
  }

  .mob-view {
    display: none;
  }

  .tbl-ctn {
    .tbl-ctn
      .MuiTableContainer-root
      .MuiTable-root
      .MuiTableHead-root
      .MuiTableCell-head {
      padding: 6px !important;
      white-space: nowrap;
    }

    .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body {
      padding: 6px !important;
      white-space: nowrap;
    }

    .MuiTableCell-sizeSmall {
      padding: 6px !important;
      white-space: nowrap;
    }
  }
}

@media only screen and (min-width: 721) and (max-width: 1120px) {
  .transaction-details-ctn {
    padding: 12px 8px;
    flex: 1 1 50%;
    .section {
      width: 50% !important;
    }
  }
}

@media only screen and (max-width: 720px) {
  .transaction-details-ctn {
    flex-direction: column;
    padding: 12px 8px;
    .section {
      width: 100% !important;
    }

    .web-view {
      display: none !important;
    }

    .mob-view {
      display: initial !important;
    }

    .mob-acc-stmt-view {
      .MuiTableCell-body {
        color: var(--text-primary);
      }
    }

    th {
      white-space: nowrap;
    }
  }
}
