// .pl-tbl-ctn {
//   margin-top: 20px;
//   border: 1px solid rgba(var(--ion-text-light-rgb), 0.1);
//   border-radius: 10px;

//   .tbl-header {
//     background: var(--ion-primary-gradient);
//     height: 18px;
//     color: var(--ion-color-warning-contrast) !important;

//     .MuiTableCell-root {
//       color: var(--ion-color-warning-contrast) !important;
//     }
//   }
//   .MuiTableRow-root {
//     .MuiTableCell-root {
//       color: var(--ion-text-light);
//     }
//   }

//   .no-data-cell {
//     color: var(--ion-text-light);
//   }
// }
// .upl-positive-val {
//   color: var(--ion-color-success);
// }
// .upl-negative-val {
//   color: var(--ion-color-lost);
// }

// @media only screen and (max-width: 721px) {
//   .pl-stmt-tbl {
//     .user-col {
//       display: none;
//     }

//     // .tbl-body {
//     //   .MuiTableRow-root {
//     //     border: 2px solid #fff;
//     //   }
//     // }
//   }
// }

// .pl-statement-report {
    .pl-summary-mob {
        border: 2px solid black;
        width: fit-content;
        padding: 2px 5px;
        float: right;
        margin: 5px 0;
        border-radius: 2px;
    }
// }
