.dashboard-ctn {
  width: 100%;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  .icon-inner, .ionicon, svg{
    fill:var(--text-primary);
  }
  .header-content {
    padding: 16px;
    color: var(--text-primary);
    margin-left: -2%;
    margin-right: -2%;
    width: 104%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 2px solid var(--border-color);
  }
  .user-details-section {
    .name {
       font-family: var(--font-family);
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 31px;
      letter-spacing: 0.01em;
      color: var(--text-primary);
    }
  }
  .logout-section {
    .logout-item {
      display: flex;
      align-items: center;
      cursor: pointer !important;
      justify-content: center;
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 140%;
      margin-left: auto;
      margin-right: auto;
      color: var(--text-primary);
    }
    .logout-icon {
      height: 20px;
      width: 32px;
      fill: var(--text-primary) !important;
    }
  }
  .dashboard-cards-ctn {
    padding-top: 12px;
    .dashboard-item {
      padding: 3px;
      min-height: 116px;
      
    background-color: var(--active-bg);
      &.change-pwd-col,
      .dashboard-item-link {
        text-decoration: none !important;
        text-align: center;
        display: flex;
        flex-direction: column;
        padding: 3px;
      }
      .dashboard-item-link {
        height: 100%;
        padding-top: 20px;
        border: 1px solid var(--active-link);
      }
      .dashboard-item-icon {
        height: 32px;
        width: 32px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;    fill:var(--text-primary);

        &.sports-icon {
          height: 42px;
          width: 42px;
          margin-bottom: 10px;
          margin-top: -5px;    fill:var(--text-primary);

        }
      }
      .dashboard-item-text {
        color: var(--text-primary);
        text-align: center;
      }
    }
  }
}
