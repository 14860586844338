.MuiPickersBasePicker-container {
  background: var(--input-background-color) !important;
  color: var(--input-text-color) !important;
  
  .MuiPickersBasePicker-pickerView {
    .MuiPickersCalendarHeader-switchHeader {
      margin: 0px;
      padding: 12px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 1px solid var(--input-text-color);
      .MuiPickersCalendarHeader-iconButton {
        // background: var(--input-background-color) !important;
        color: var(--input-text-color) !important;
        opacity: 1;
        border-radius: 4px;
      }
    }
    .MuiPickersCalendarHeader-daysHeader {
      .MuiPickersCalendarHeader-dayLabel {
        padding-top: 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
      }
    }
    .MuiPickersCalendar-transitionContainer {
      .MuiIconButton-root {
        color: var(--input-text-color) !important;
        opacity: 1;
        border-radius: 4px;
      }
      .MuiPickersDay-hidden {
        color: red !important;
      }
      .MuiPickersDay-daySelected {
        background: var(--date-selected-bg) !important;
        color: var(--date-selected-color) !important;
      }
    }
    
  }
}
