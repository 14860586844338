.help-text {
  margin-left: 15%;
  margin-right: 15%;
  .help-line {
    margin-bottom: 2%;
  }
  .help-header {
    color: var(--ion-color-primary);
    margin-bottom: 2%;
  }
}
