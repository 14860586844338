.alert-message {
  border: 1px solid var(--ion-text-dark);
  border-radius: 6px;
  padding: 12px;
  margin: 2px;
  font-family: Inter;
  font-size: 14px;
  color: rgb(102, 60, 0);
  background-color: var(--ion-text-light);
}
