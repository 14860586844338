.change-pwd-form-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
  // background-image: var(--report-page-bg) !important;
  // background: var(--ion-background);
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--text-primary);
  width: 100%;
  .usr-input,
  .pwd-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
    .login-input-field{
      min-width: 100%;
    }
  }
  .infoicon {
    color: var(--text-common-dark);
    vertical-align: sub;
  }

  .submit-form-btn {
    // margin-top: 20px !important;
    margin-bottom: 20px;
    width: 60%;
    max-width: unset;
    height: 48px;
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    .MuiButton-label {
      color: var(--text-common-dark) !important;
    }
  }

  .accept-terms-input {
    padding-top: 14px;
    max-width: unset;
    color: var(--text);

    .MuiFormControlLabel-root {
    color: var(--text);
      margin-left: 0px;
      margin-right: 0px;
      align-items: flex-start;
      .MuiFormControlLabel-label {
    color: var(--text) !important;
        margin-left: 10px;
        color: var(--text-primary);
      }
    }

    .MuiFormHelperText-root {
      color: red;
    }

    .accept-terms-checkbox {
      color: var(--text-primary) !important;
      position: relative !important;
    }
  }
}

.error-msg {
  color: var(--ion-color-danger);
  margin-top: 20px;
  text-align: center;
}
.success-msg {
  color: var(--ion-color-success);
  margin-top: 20px;
  text-align: center;
}
.user-name,
.pwd-field {
  max-width: unset;
  height: 48px;
}

.input-tooltip {
  color: var(--ion-text-color);
  font-size: 20px;
  padding-left: 4px;
}

.input-label {
  color: gray;
}

// @media (min-width: 481px) and (max-width: 767px) {
//   .user-name,
//   .pwd-field,
//   .submit-form-btn {
//     max-width: 298px;
//   }
// }

// @media (min-width: 320px) and (max-width: 480px) {
//   .user-name,
//   .pwd-field,
//   .submit-form-btn {
//     max-width: 250px;
//   }
// }
