.account-details-ctn {
  display: flex;

  .account-detail {
    display: block;
    background-color: #eee;
    margin-right: 8px;
    padding: 8px 15px;
    min-width: 180px;
    text-transform: capitalize;

    .MuiButton-label {
      background-color: unset !important;
    }

    @media screen and (max-width: 720px) {
      font-size: 12px;
    }

    &.active {
      background-color: var(--button);
      color: #fff;
    }
  }
}

.account-inputs {
  .payment-detail {
    padding-bottom: 10px;

    .payment-detail-input {
      background-color: var(--button);
      color: #fff;
      border-radius: 4px;
      cursor: pointer;
      padding: 2px 8px;
      font-weight: 600;
      margin-left: 10px;
    }
  }

  .account-input {
    margin-top: 10px;

    .MuiInputBase-root {
      color: unset !important;
      border: 0;
    }

    .account-label {
      font-size: 14px;

      @media screen and (max-width: 720px) {
        font-size: 12px;
      }
    }

    .deposit-image {
      width: 100%;
    }

    .acc-input {
      border: 0;
      background-color: #eee;
      border-radius: 10px;
      padding: 14px;
      min-width: 100%;

      @media screen and (max-width: 720px) {
        font-size: 12px;
        position: relative;
      }

      &.MuiFormControl-root {
        padding: 0;

        .MuiInputBase-root {
          &::before {
            border-bottom: 0;
          }

          .MuiInputBase-input {
            font-size: 15px;
            font-family: 'Montserrat';

            @media screen and (max-width: 720px) {
              font-size: 12px;
              color: var(--color-black);
            }
          }
        }
      }
    }
  }

  .upload-btn {
    background-color: var(--button);
    color: #fff;
    text-transform: capitalize;
    margin-top: 8px;

    .MuiButton-label {
      background-color: unset !important;
    }
  }

  .submit-payment-btn {
    margin-top: 16px;
    margin-bottom: 35px;
    background-color: var(--button);
    padding: 8px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 15px;
    min-width: 100%;

    .MuiButton-label {
      background-color: unset !important;
    }

    @media screen and (max-width: 720px) {
      padding: 4px;
      border-radius: 6px;
    }
  }
}

.deposit-form-ctn {
  .MuiTab-textColorInherit {
    @media screen and (max-width: 720px) {
      color: var(--text);
    }
  }
  .MuiTab-textColorInherit.Mui-selected {
    border-bottom: 2px solid var(--button);
    @media screen and (max-width: 720px) {
      color: var(--text);
    }
  }

  .payment-option-title {
    font-family: Blinker;
    font-weight: bold;
  }

  .MuiTypography-root {
    padding: 0;
  }

  .account-mt-10 {
    margin-top: 10px;
  }

  .payment-options {
    // margin: 12px 0 20px 0;
    border-bottom: 1px solid #cdcdcd;

    .payment-btn {
      margin-right: 10px;
      padding: 5px 9px;
      min-height: 40px;
      min-width: 72px;
      border-radius: 0px;
      background-color: var(--payment-background);

      .MuiButton-label {
        background-color: unset !important;
      }
    }

    .active {
      background-color: var(--button);
      border-bottom: 3px solid var(--button);
      // color: #fff;
    }
  }
}

.payment-deposit-ctn {
  div,
  span {
    font-family: 'Montserrat';
  }

  .deposits-header {
    padding: 0;
  }

  margin: 16px 15px;

  @media screen and (max-width: 720px) {
    margin: 10px 0 !important;
  }

  .img-page-title-row.mob-view {
    margin-bottom: 12px;
  }

  .payment-option-title,
  .account-details-title,
  .uploaded-image-title {
    display: inline-grid;
    width: 100%;
    font-size: 15px;
    line-height: 19px;
    padding-bottom: 6px;
    font-weight: bold;
    font-family: 'Montserrat';

    @media screen and (max-width: 720px) {
      font-size: 13px;
      color: var(--text);
    }
  }

  .account-input-title {
    display: inline-grid;
    width: 100%;
    font-size: 15px;
    line-height: 19px;
    font-weight: bold;

    @media screen and (max-width: 720px) {
      font-size: 13px;
      color: var(--text);
    }
  }

  .payment-form-divider {
    height: 1px;
    background: rgb(212, 211, 211);
    margin: 26px 0 15px 0;
  }
}

.withdraw-form-divider {
  height: 1px;
  background: rgb(212, 211, 211);
  margin: 26px 0 15px 0;
}

.my-payment-option {
  margin-bottom: 20px;
}

.account-details-ctn {
  .add-btn {
    background-color: var(--button);
    margin-left: 4px;

    .add-account {
      background: white;
      // margin-top: 3px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
      height: 30px;
      width: 30px;
      border-radius: 100px;
      size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .MuiButton-label {
      background-color: unset !important;
    }
  }
}

.payment-withdraw-ctn {
  .payment-option-title {
    font-weight: bold;

    @media screen and (max-width: 920px) {
      color: var(--text) !important;
    }

    font-family: Blinker;
  }
}

.deposit-background-ctn {
  @media screen and (max-width: 720px) {
    // background-color: #fff;
    background-color: var(--background-image-color);
    position: relative;
    padding: 20px 10px;
    border-radius: 25px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: var(--background-image-opacity);
      background-image: url('../../assets/images/home/homeview/background.svg');
      border-top-left-radius: 35px;
      border-top-right-radius: 35px;
    }
  }
}

.router-ctn {
  .payment-withdraw-ctn {
    @media screen and (max-width: 920px) {
      padding: 16px 10px;
      margin-top: 10px;
      // background-color: #fff;
      background-color: var(--background-image-color);
    }

    .payment-option-title {
      font-weight: bold;

      @media screen and (max-width: 920px) {
        color: var(--text) !important;
      }

      font-family: Blinker;
    }
  }
}
