.mobile-sub-header.app-sub-header {
    margin: 0;
    padding: 0;
    padding-left: 10.5px;

    .actions-list {
        padding-left: 0;
        padding-right: 0;
    }
    .left-side {
        height: 35px;
        padding-top: 1px;
        .card {
            margin-right: 14px;

            &.active{
                .indian-svg {
                    opacity: 1;
                }    
                svg {
                    path {
                        fill: white;
                    }
                }
            }
            svg {
                margin-right: 4.5px;
            }

            .label-ctn {
                .label {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }
}