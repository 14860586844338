.reset-pwd-modal {

  .MuiDialog-root {
    .MuiDialog-container {
      .MuiDialog-paper {
        position: relative;

        .MuiDialogTitle-root {
          text-align: center;
          height: auto;

          @media screen and (min-width: 1200px) {
            height: 60px;
          }

          .MuiTypography-root {
            color: var(--text-primary);
            font-weight: bold;
          }
        }

        .MuiIconButton-root {
          position: absolute;
          top: 8px;
          right: 8px;
          opacity: 1;
          color: var(--text-primary);

        }

        .MuiDialogContent-root {
          // background: var(--dialog-content-background);
          padding: 0px 28px !important;
          color: var(--text-primary);

          .MuiButton-containedPrimary {
            background: var(--button) !important;
            color: var(--text-copyright);
            text-transform: none;
            font-weight: 600;
          }

          .MuiButton-containedPrimary:hover {
            background-color: var(--button);
            color: var(--text-copyright);
            text-transform: none;
          }
        }
      }

    }
  }

  .MuiPaper-root {
    background-color: var(--background-image-color);
    font-family: 'Montserrat';

    .modal-title {
      .MuiTypography-root {
        color: var(--text);
        font-family: 'Montserrat';
      }
    }
    
    .input-label {
      color: var(--text);
    }
  }

  .MuiPaper-root::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: var(--background-image-opacity);
    background-image: url('../../assets/images/home/homeview/background.svg');
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
  }

  .MuiInputBase-root {
    background: var(--form-input-background) !important;
    color: var(--text) !important;
    border-radius: 6px;
    border: 1px solid #7C8288;

    .MuiOutlinedInput-input {
      font-family: 'Montserrat' !important;
      font-style: normal;
      font-weight: 400;
      font-size: 16px !important;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: var(--sub-header-btn-text) !important;
      border-radius: 6px;
    }
  }
  
}