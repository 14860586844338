.dashboard-view-ctn {
  padding-left: 2%;
  padding-right: 2%;
  .not-logged-in-msg {
    margin-top: 20px;
    font-size: 20px;
    font-family: var(--headers-font-family);
    text-transform: none;
    text-align: center;
  }
}
