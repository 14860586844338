.marquee {
  height: 34px;
  overflow: hidden;
  position: relative;
  background: rgba(19, 97, 46, 0.1);
  font-size: 19px;
  font-weight: bold;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .info {
    font-size: 14px;
    background: #B37318;
    color: #000;
    position: absolute;
    z-index: 999;
    height: 100%;
    line-height: 44px;
    padding-left: 6px;
    padding-right: 6px;
    display: flex;
    align-items: center;
  }

  .view-all {
    right: 0%;
    cursor: pointer;
    background: #B37318;
  }
}

.marquee span {
  font-family: var(--font-family);
  color: var(--text-primary);
  font-size: 14px;
  margin-left: 10px;
  position: absolute;
  white-space: pre;
  width: max-content;
  height: 100%;
  width: 100%;
  margin: 0;
  line-height: 35px;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: scroll-left 1s linear infinite;
  -webkit-animation: scroll-left 1s linear infinite;
  animation: scroll-left 20s linear infinite;

  @media screen and (max-width: 720px) {
    font-size: 11px;
  }

  &:hover {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
    cursor: pointer;
  }
}

@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
  }

  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 720px) {
  .marquee {
    height: 34px;
    font-size: 19px;

    span {
      line-height: 34px;
    }
  }
}

.admin-notification-modal {
  .MuiDialog-paperWidthXs {
    max-width: 60%;
  }
}

.stl-dialog-title {
  .modal-title {
    .mic-icon {
      vertical-align: middle;
    }

    @media screen and (max-width: 720px) {
      font-size: 14px;
    }
  }
}

.MuiDialog-root .MuiDialog-container .MuiDialog-paper .MuiDialogContent-root {
  // background: white !important;
  padding: 10px 28px !important;
}

@media screen and (max-width: 720px) {
  .MuiDialog-root .MuiDialog-container .MuiDialog-paper .MuiDialogContent-root {
    padding: 10px !important;
  }
}