.app-sub-header {
  height: 60px;
  background: var(--sub-header-background);
  border-bottom: 0px;
  display: flex;
  align-items: center !important;
  margin-left: 18px;
  margin-right: 18px;

  .actions-list {
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    
    .MuiTabs-flexContainer {
      justify-content: space-between;
    }

    .left-side {
      display: flex;
      flex-direction: row;
      justify-content: space-between !important;
      align-items: center;
      height: 60px;

      .card {
        display: flex;
        margin-right: 45px;
        cursor: pointer;
        text-decoration: none;
        position: relative;

        &.active.home-link {
          .border-bottom {
            top: 35px;
          }
        }

        path {
          fill: var(--sub-header-text);
        }

        &.active {
          .label-ctn .label {
            opacity: 1;
            color: var(--active-sub-header-text);
          }

          .indian {
            opacity: 1;
          }

          svg {
            path {
              fill: var(--active-sub-header-text);
            }
          }

          .border-bottom {
            display: block;
            height: 4px;
            background: #CE8D2E;
            position: absolute;
            width: 100%;
            top: 38px;
          }
        }

        &.selected {
          .label {
            opacity: 1 !important;
          }
        }

        svg {
          // height: 18.75px;
          // width: 18.75px;
          margin-right: 10px;
        }

        .live-icon {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 12px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.03em;
          text-transform: capitalize;
          color: #FFFFFF;
          border-radius: 6px;
          background-color: #9b9285;
          padding: 6px 7px;
          margin-right: 10px;
        }

        // &.active {
        //   // .live-icon {
        //   //   // background-color: var(--active-sub-header-text);
        //   //   // color: #fff;
        //   // }
        // }
        .label-ctn {
          display: flex;
          align-items: center;

          .label {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            display: flex;
            align-items: center;
            letter-spacing: 0.07em;
            text-transform: uppercase;

            color: var(--sub-header-text);
          }
        }
      }

      .logo-ctn {
        height: 40px;
        margin-right: 30px;

        .logo {
          height: 40px;
        }
      }

      .nav-link {
        text-decoration: none;
        text-align: center;
        margin-left: 0.7rem;
        margin-right: 0.7rem !important;
        display: flex;
        align-items: center !important;

        .nav-link-btn {
          border-radius: 0px;
          color: var(--text) !important;
          text-transform: uppercase;
          font-size: 16px;
          vertical-align: middle;
          font-weight: 400;
        }
      }

      .active {
        // border-bottom: 3px solid var(--active-link);

        .nav-link-btn {
          color: var(--active-link) !important;
          transition: width 0.1s;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }

    .right-side {
      display: flex;
      flex-direction: row;
      justify-content: flex-end !important;
      align-items: center;
      width: 50%;

      .header-actions {
        .header-right-actions {
          display: flex;
        }

        .notifications-img {
          padding: 0;

          &.MuiButtonBase-root .MuiButton-label {
            padding: 0;
            background: transparent !important;
          }
        }

        .theme-toggle {
          height: 40px;
          background: var(--sub-header-btn-background);
          border: 1px solid #CF8D2E;
          display: flex;
          border-radius: 4px;
          align-items: center;
          padding-left: 12px;
          padding-right: 12px;
          margin-right: 12px;

          ion-icon {
            padding-left: 14px;
            cursor: pointer;
          }

          .sunny {
            height: 24px;
            width: 24px;
            color: #CE8D2E;
          }

          .moon {
            height: 24px;
            width: 24px;
          }

          .theme-text {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;

            color: var(--sub-header-btn-text);
          }

          .theme-btn {
            background: var(--theme-toggle-btn);
            border-radius: 100px;
            padding: 0;
            height: 24px;
            width: 24px;
            min-width: unset;
            margin-left: 13px;

            .MuiButton-label {
              padding: 0;
              background-color: none;
            }
          }
        }

        .MuiButtonBase-root .MuiButton-label {
          width: unset;
          padding: 9px 22px;
          text-transform: capitalize;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
        }

        .login-btn {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;


          &.MuiButtonBase-root .MuiButton-label {
            // background-color: #fff !important;
            color: white !important;
            background: #000000 !important;
            border: 1px solid #CF8D2E;
            border-radius: 6px;

          }

          .username-text {
            padding-left: 14px;
            padding-top: 9px;
            padding-bottom: 9px;
            padding-right: 13px;
          }

          .downArrow {
            path {
              fill: #CF8D2E;
            }
          }

          &.p-0 {
            padding: 0;

            &.MuiButtonBase-root .MuiButton-label {
              padding: 0;
              padding-right: 13px;
            }
          }
        }

        .signup-btn {
          &.MuiButtonBase-root .MuiButton-label {
            // background-color: #EC3451 !important;
            color: var(--text) !important;
            background-color: var(--header-btn-background) !important;
            border: 1px solid #CF8D2E;
            border-radius: 6px;
          }
        }

        .balance-btn.MuiButtonBase-root .MuiButton-label {
          padding: 9px 12px !important;

          path {
            fill: #CE8D2E;
          }
        }
      }

      .search-bar {
        width: 350px;
        position: relative;

        .ion-live-casino {
          position: fixed;
          width: 350px;
          background-color: #454646;
          z-index: 1111;
          top: 110px;
          background: linear-gradient(180deg, #000000 0%, #2A2A2B 100%);
          border: 1px solid #CF8D2E;
          padding: 5px 15px;

          .ion-live-card {
            // border-top: 1px solid #CF8D2E;
            border-bottom: 1px solid #CF8D2E;
            color: #f4f4f4;
            padding: 5px 0;
            cursor: pointer;
          }

          .ion-live-card:last-child {
            border-bottom: 0;
          }
        }

        .search-button {
          display: flex;
          align-items: center;
          padding-right: 12px;
          cursor: pointer;

          svg path {
            fill: var(--text);
          }
        }

        .search {
          display: flex;
          flex-direction: row;
          text-align: left;
          text-decoration: none;
          vertical-align: middle;
          user-select: none;
          height: 40px;
          background: var(--sub-header-btn-background);
          border: 1px solid #CF8D2E;
          border-radius: 4px;

          @media screen and (max-width: 720px) {
            min-width: 100px;
            max-width: 131px;
            height: 34px;
          }

          text-transform: uppercase;
        }

        ::placeholder {
          font-size: 14px !important;
          color: var(--text) !important;
          font-family: 'Inter';
          font-weight: 300 !important;
        }

        .input {
          border-right: none;
          outline: none;
          color: var(--sub-header-btn-text) !important;
          font-family: var(--font-family);
          border: 0px !important;
          background-color: var(--sub-header-btn-background) !important;
          padding: 15px;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          width: 100%;
          border-radius: 4px;

          ::placeholder {
            font-family: 'Montserrat';
          }
        }

        .searchTerm:focus {
          color: var(--text) !important;
        }

        .searchButton {
          background-color: var(--active-link) !important;
          text-align: center;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 0.275rem 1.2rem;

          @media screen and (max-width: 720px) {
            padding: 0.275rem 1.4rem;
          }

          width: 20px;
          background-image: url('../../assets/images/common/search.svg');
          background-position: center;
          background-repeat: no-repeat;
          border-top-right-radius: 15px !important;
          border-bottom-right-radius: 15px !important;
        }
      }

      .btn {
        background: var(--button) !important;
        border: 1px solid var(--heading-color);
        margin-left: 8px;
        border-radius: 15px;
        height: 40px;

        @media screen and (max-width: 720px) {
          max-width: 80px;
          font-size: 11px;
          height: 38px;
        }

        width: 100px;

        .MuiButton-label {
          text-transform: capitalize;
          color: var(--text-copyright) !important;
          font-weight: bold;
        }

        transition: all 0.3s ease;
        overflow: hidden;
      }

      // .theme-btn {
      //   background-color: var(--headerbg);
      //   width: 40px;
      //   margin-left: 10px;
      //   height: 40px;
      //   border-radius: 15px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   cursor: pointer;

      //   .icon {
      //     color: var(--text);
      //     fill: var(--text);
      //   }

      //   .MuiButton-label {
      //     background-color: var(--headerbg);
      //   }
      // }
    }
  }
}

@media screen and (max-width: 720px) {
  .app-sub-header {
    max-height: 34px;
    padding-bottom: 0px;
    padding-left: 5%;
    background-color: var(--ion-secondary);
    border-bottom: 0px;

    .actions-list {
      display: flex;
      justify-content: flex-start;
      overflow-x: scroll;
      min-height: 30px;
      // padding: 6px 0 0 0;

      .nav-link {
        border-radius: 0px;
        margin-top: -5px;

        .MuiButton-root {
          padding-bottom: 2px;
          padding-top: 2px;

          .MuiButton-label {
            min-width: 54px;
            font-size: 12px;
            font-weight: 800;
            white-space: nowrap;
            font-family: var(--font-family);
          }
        }
      }
    }
  }
}


.user-img-border {
  border-radius: 4px 0 0 4px;
}

#demo-customized-menu {
  svg {
    width: 30px;
  }


  .menu-item-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7C8288;
    padding-left: 3px;
    padding-right: 0;

    @media screen and (max-width: 720px) {
      padding-top: 10px;
      padding-bottom: 10px;
      height: unset !important;
    }
  }

  .menu-border-bottom {
    height: 1px;
    background-color: #454646;
    margin: 0 9px;

    @media screen and (max-width: 720px) {
      margin: 0;
    }
  }

  .MuiPaper-root {
    border-radius: 0;
    background: linear-gradient(180deg, #000000 0%, #2A2A2B 100%);
    border: 1px solid #CF8D2E;
    top: 44px !important;

    @media screen and (min-width: 720px) {
      top: 48px !important;
      width: 142px;
    }

    @media screen and (max-width: 720px) {
      padding: 0 14px;
    }
  }

  .logout-btn {
    width: 100%;

    .MuiButton-label {
      // width: 89px;
      padding: 9px 36px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: capitalize;
      color: white !important;
      background: #CF8D2E !important;
      border-radius: 6px;
    }
  }
}