.MuiPaper-root {
  .MuiDialog-paper {
    margin: 0px;
    background-image: var(--report-page-bg) !important;
    background: var(--ion-background);
  
  }
}
.MuiDialog-paperFullWidth {
  background-image: var(--report-page-bg) !important;
  background: var(--ion-background);
}
