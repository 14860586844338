.ps-iframe-ctn {
  background: var(--ion-background);
  width: 100%;
  height: 90vh;
  scrollbar-width: none;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    border: 0;
  }
}
