.wacs-iframe-ctn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 85vh;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
}
