.notification-ctn {
  min-height: 50px;
  padding: 18px;

  .message {
    text-transform: uppercase;
  }

  @media screen and (max-width: 720px) {
    font-size: 10px;
  }
  @media screen and (min-width: 720px) and (max-width: 1200px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1200px) {
    font-size: 18px;
  }
}
