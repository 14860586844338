.my-wallet-ctn {
  background-color: var(--background-image-color);
  margin-top: 58px;
  border-radius: 50px;
  padding-bottom: 50px;
  min-height: 55vh;
  position: relative;

  .title-border-line {
    height: 1px;
    width: 100%;
    background: var(--background-gradient);
    position: relative;
    top: -12px;
    @media screen and (max-width: 720px) {
      top: -18px;
    }
  }

  .MuiTableRow-root {
    border-bottom: 0 !important;
  }

  .filters-row .date-section {
    width: 100% !important;
  }
  .tbl-ctn {
    .MuiTableCell-head {
      padding: 0 14px !important;
      @media screen and (max-width: 920px) {
        padding: 12px 14px !important;
      }
    }
    .from-to-cell {
      display: flex;
      align-items: center;

      svg {
        padding: 0 4px;
      }
    }
  }
}

.my-wallet-ctn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: var(--background-image-opacity);
  background-image: url('../../assets/images/home/homeview/background.svg');
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}
