.ind-casino-iframe-ctn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 100%;
  height: 104vh;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;

    .content {
      max-width: auto !important;
    }
  }
}

@media screen and (max-width: 720px) {
  .ind-casino-iframe-ctn {
    padding: 0;
    height: inherit;
    border: 0;
    border-radius: 0;

    iframe {
      border: 0;
      padding-bottom: 4px;
      -webkit-overflow-scrolling: touch;
      overflow: auto;
    }
  }
}
