.footer-ctn {
  margin-top: 10px;
  padding: 8px;
  background-color: var(--footer-bg);

  .footer-ctn-game {
    .footer-heading {
      justify-content: center;

      .title {
        color: var(--active-link);
        margin-top: 10px;
        font-size: 24px;
        margin-bottom: 10px;
        text-transform: uppercase;

        @media screen and (max-width: 720px) {
          font-size: 20px;
        }
      }
    }

    .provider-content {
      display: grid;
      grid-gap: 2px;
      grid-template-columns: repeat(7, 1fr);
      padding-left: 2%;
      padding-right: 2%;

      .img-contain {
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
        margin-right: 15px;
        filter: var(--icon);

        @media screen and (max-width: 720px) {
          margin-left: 8px;
          margin-right: 8px;
        }

        img,
        .provider-img {
          display: block;
          height: 65%;
          width: 100%;
          image-rendering: -moz-crisp-edges !important;
          /* Firefox */
          image-rendering: -o-crisp-edges !important;
          /* Opera */
          image-rendering: -webkit-optimize-contrast !important;
          /* Webkit (non-standard naming) */
          image-rendering: crisp-edges !important;
          -ms-interpolation-mode: nearest-neighbor !important;
          /* IE (non-standard property) */
        }
      }
    }

    .provider-ctn {
      display: flex;
      justify-content: center;

      .footer-opetion-ctn {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 22%;

        @media screen and (max-width: 720px) {
          width: 60%;
        }

        padding: 10px;
        margin-bottom: 2%;
        margin-top: 2%;

        .footer-opetion {
          display: flex;
          align-items: center;
          color: var(--text);
          cursor: pointer;

          .book {
            fill: var(--text);
            color: var(--text);
            margin-right: 8px;
            font-size: 45px;
          }

          .label-ctn {
            display: flex;
            flex-direction: column;

            .text {
              font-size: 14px;
            }

            .text1 {
              font-size: 10px;
              color: var(--active-link);
              text-decoration: none;
            }
          }
        }

        .footer-opetion1 {
          color: var(--text);
          cursor: pointer;

          .circle {
            height: 45px;
            width: 45px;
            border-radius: 50%;
            border: 1px solid var(--text);
            justify-content: center;
            display: flex;
            align-items: center;
            text-align: center;

            .label {
              font-size: 18px;
              font-weight: bold;
              text-align: center;
            }
          }
        }
      }

      .logo {
        margin-bottom: 1%;
        margin-top: 2%;
        filter: var(--icon);
      }

      .text-content {
        width: 50%;
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        color: var(--text);
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.1rem;
        line-height: 25px;

        @media screen and (max-width: 720px) {
          width: 80%;
        }
      }

      .pro-content {
        width: 60%;
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        color: var(--text);

        @media screen and (max-width: 720px) {
          width: 100%;
        }

        .img-contain {
          margin-top: 20px;
          margin-bottom: 10px;
          width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 15px;
          margin-right: 15px;

          @media screen and (max-width: 720px) {
            margin-left: 8px;
            margin-right: 8px;
          }

          img,
          .provider-img {
            display: block;
            height: 100%;
            width: 100%;
            -webkit-filter: blur(0px) !important;
            image-rendering: -moz-crisp-edges !important;
            /* Firefox */
            image-rendering: -o-crisp-edges !important;
            /* Opera */
            image-rendering: -webkit-optimize-contrast !important;
            /* Webkit (non-standard naming) */
            image-rendering: crisp-edges !important;
            -ms-interpolation-mode: nearest-neighbor !important;
            /* IE (non-standard property) */
          }
        }
      }
    }
  }
}

.mob-footer {
  background: #000000;
  width: 100%;
  // position: fixed !important;
  // bottom: 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 60px;
  z-index: 1111;
  transform: translate3d(0, 0, 0);
  position: fixed;
  bottom: 0;
  box-shadow: 0px 20px 50px #CF8D2E;

  .footer-btn {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    color: #7C8288;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 68px;

    .svg-container {
      display: none;
      width: 44px;
      height: 44px;
      border-radius: 50px;
      background-color: #CF8D2E;

      .svg {
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.03em;
      }

    }

    .home-footer-icon {
      path {
        fill: #7c8288;
      }
    }

    .svg-text {
      display: none;
      font-weight: 400;
      font-size: 9px;
      line-height: 12px;

      align-items: center;
      text-align: center;
      text-transform: capitalize;
      max-width: min-content;
      color: #FFFFFF;
    }

    .svg-top-border {
      display: none;

      path {
        fill: #000000;
      }
    }

    &.active {

      position: relative;
      top: -16px;

      .svg-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: -14px;

        .svg {
          color: white;

          svg path {
            fill: white;
          }
        }
      }

      .svg-top-border {
        display: block;
        position: relative;
        top: 7px;
      }

      .svg-text {
        padding-top: 4px;
        display: block;
        position: relative;
        top: -14px;
      }

      .svgs {
        display: none;
      }

      .svgs {
        path {
          fill: white;
        }
      }
    }

    .MuiButton-label {
      background-color: transparent !important;
    }

    background-color: transparent;
  }
}