.terms-ctn {
  padding-left: 5%;
  padding-right: 5%;
  color: var(--text);
  padding-top: 2%;
  div, p, span, h1, h2, h3, ion-title {
    font-family: 'Montserrat';
  }
  .title-default {
    padding-left: 0;
    text-decoration: underline;
    font-weight: bold;
  }
  .terms-conditions-ctn {
    font-size: 13px;
    text-align: justify;
    color: var(--text);
    font-weight: 300;
    background-color: var(--ion-card-background);
    .content-ctn {
      .terms-desc-heading {
        color: var(--text);
      }
    }
  }
}
