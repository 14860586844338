.user-commission-ctn {
  .my-wallet-header {
    .image-title {
      display: contents;
      text-align: center;
    }
  }

  .filters-row {
    margin-left: 10px;
    display: flex;
    justify-content: flex-end;

    div {
      margin-right: 8px;

      .MuiFormControl-marginNormal {
        margin-top: 4px;
      }
    }

    .clim-action-web {
      margin-top: 35px;
      background: var(--button);
    }
  }

  .info-img {
    color: var(--ion-color-primary);
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .error-msg {
    color: var(--ion-color-danger);
    text-align: center;
  }

  .nav-link {
    color: var(--page-titles-color);
    text-decoration: none;
  }

  .balance-history-tbl-ctn {
    .table-ctn {
      .MuiPaper-root {
        margin-top: 0px;
      }
    }
  }

  .mob-view {
    display: none;
  }
}

@media only screen and (max-width: 721px) {
  .user-commission-ctn {
    .user-wallet-filters-row .MuiInputBase-root {
      height: 32px;
    }

    .header-ctn,
    .image-title {
      padding-bottom: 0;
      margin-top: 0 !important;
    }

    .user-wallet-filters-row {
      margin-top: 4px;
    }

    .user-wallet-filters-row {
      justify-content: flex-end;
    }

    .mob-view {
      display: initial;
    }

    .web-view {
      display: none;
    }
  }
}
