.odds-terms-condi-ctn {
  height: 520px;
  max-height: 520px;
  padding: 20px;
  width: 100%;

  .sub-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  .rules-accordion {
    .MuiPaper-root {
      .MuiAccordionDetails-root {
        display: grid;
      }

      .MuiDialog-container .MuiDialog-paper .MuiIconButton-root {
        top: auto;
      }
    }

    .MuiAccordionSummary-root {
      max-height: 52px;
    }

    .MuiAccordionSummary-root.Mui-expanded {
      max-height: 52px;
    }
  }

  .MuiAccordion-root {
    .MuiAccordionSummary-root {
      background: #495264 !important;

      .MuiTypography-root {
        color: #fff !important;
        font-family: var(--font-family);
        font-size: 18px;
      }
    }

    .MuiIconButton-root {
      right: 20px !important;
      color: #fff !important;
    }

    .MuiAccordionDetails-root {
      display: grid;
    }
  }
}

@media screen and (max-width: 720px) {
  .odds-terms-condi-ctn {
    padding: 0;
  }
}
