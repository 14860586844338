.dc-page-bg {
  min-height: 100%;
  margin-top: 16px;

  .tab-casino-btn {
    background-color: #1c1a1a;
    color: var(--button);
    min-width: 80px !important;
    margin-right: 10px;
    border-radius: 8px;
    border: 1px solid var(--button);

    .MuiTab-wrapper {
      color: var(--button);
      text-transform: capitalize;
    }

    .casino-icon {
      height: 24px;
      width: 24px;
    }

    path {
      fill: var(--text);
    }
  }

  ion-select-popover ion-list ion-item {
    min-width: 210px;
    color: var(--text);
  }

  .item .sc-ion-label-ios-h {
    min-width: 126px;
    color: var(--text);
  }

  .dc-ctn {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1%;
    box-sizing: border-box;
    padding-bottom: 40px;
    background-color: var(--background-image-color);
    border-radius: 40px;
    margin: 0 18px 0 18px;
    position: relative;

    @media screen and (max-width: 720px) {
      padding-left: 7px;
      padding-right: 7px;
      width: 100%;
      margin: 0;
      padding-bottom: 110px;
    }

    .search-options-ctn {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 20px;
      margin-right: 16px;

      .casino-search {
        background-color: var(--sub-header-btn-background);
        width: 220px;
        color: var(--text);
        padding: 20px 10px;

        &.mr-10 {
          margin-right: 10px;
        }
        @media screen and (max-width: 768px) {
          width: 180px;
          font-size: 14px;
        }
      }

      @media screen and (max-width: 720px) {
        display: block;
        gap: 10px;
        margin: 12px 10px 16px 10px;
        .mob-mt-10 {
          margin-top: 20px;
        }

        .casino-search {
          padding: 14px 10px;
          font-size: 13px;
        }
      }
    }

    .title-border-line {
      height: 2px;
      width: 100%;
      background: var(--background-gradient);
      position: absolute;
      top: 27px;

      @media screen and (max-width: 720px) {
        top: 24px;
        width: 95%;
      }
    }

    .casino-filters-row {
      // display: flex;
      // flex-direction: row;
      // margin-bottom: 1%;
      // align-items: center;

      z-index: 1111;
      position: relative;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--text);
      background: #CF8D2E;
      width: fit-content;
      padding: 6px 7px;
      border-radius: 0 10px 0 10px;
      margin: 1px auto 16px auto;

      @media screen and (max-width: 720px) {
        margin: 8px auto 0 auto;
      }

      .title {
        // color: var(--text);
        margin-left: 4px;
        text-transform: capitalize;
        // font-family: 'Montserrat';
        // font-style: normal;
        // font-weight: 600;
        // font-size: 24px;
        // line-height: 16px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 10.3506px;
        line-height: 13px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #FFFFFF;

        @media screen and (max-width: 720px) {
          // font-size: 16px;
          margin-left: 0px;
          font-weight: 700;
          font-size: 10.3506px;
          line-height: 13px;
        }
      }

      .select-filter {
        min-width: 270px;
        max-width: 280px;

        @media screen and (max-width: 720px) {
          min-width: 130px;
          max-width: 150px;
          border-radius: 10px;
          margin-right: 0px;
          font-size: 12px;
        }

        margin: auto;
        margin-right: 10px;
        height: 38px;
        border-radius: 15px;
        color: var(--text);
        background-color: var(--ion-secondary);
        border: 0px !important;
      }
    }
  }

  .dc-ctn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: var(--background-image-opacity);
    background-image: url('../../../assets/images/home/homeview/background.svg');
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
  }

  .dc-ctn {
    .dc-games-ctn {
      .dc-ion-col {
        padding: 0 7px 16px 7px;

        @media screen and (max-width: 720px) {
          padding: 5px 4px 6px 4px;
        }
      }

      @media screen and (max-width: 720px) {
        // padding-left: 5%;
        // padding-right: 5%;
        margin-top: 10px;
      }

      .border-1 {
        border: 1px solid var(--active-link) !important;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .provider-ctn {
          display: flex;
          justify-content: center;

          .dc-img {
            width: 60%;
            height: 100px;
          }
        }
      }

      .container1 {
        position: relative;
        width: 100%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3.75746px;
        cursor: pointer;

        @media screen and (max-width: 720px) {
          background: #EFEFEF;
          // border: 1px solid #C9C9C9;
          border-radius: 1.91665px;
        }
      }

      .dc-img {
        display: block;
        padding: 13px 11px 0 11px;
        background-color: var(--card-background);
        border-radius: 3.75px 3.75px 0 0;
        height: 175px;
        width: 100%;
        image-rendering: unset !important;
        @media screen and (max-width: 1440px) {
          height: 125px;
        }

        @media screen and (max-width: 720px) {
          height: 80px;
          padding: 6px 6px 0 6px;
          border-radius: 2px 2px 0 0;
        }
        @media screen and (max-width: 375px) {
          height: 65px;
        }
      }

      .game-name {
        // height: 46px;

        // &.game-name1 {
        //   height: 30px;
        // }


        background-color: var(--card-background) !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 3.75px 3.75px 0 0;

        width: 100%;
        padding: 12.3px 0 12.7px 14.4px;

        @media screen and (max-width: 720px) {
          padding: 6px 0 6px 6px;
          border-radius: 0 0 2px 2px;
        }

        .name {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 20px;
          color: var(--text) !important;
          text-align: left;
          cursor: pointer;
          letter-spacing: 0.02em;
          text-transform: capitalize;

          @media screen and (max-width: 720px) {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            white-space: nowrap;
          }

          .favoriteIcon {
            margin-left: 4px;
          }
        }

        .info-img {
          height: 16.5px;
        }

        .p-name {
          font-weight: 500;
          font-size: 10px;
          text-align: center;
          text-transform: uppercase;
          display: none;
        }
      }

      .overlay1 {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: 0.5s ease;
        background: rgba(0, 0, 0, 0.8);
        // border-radius: 15px;
        border: 1px solid var(--border-color);
      }

      .container1:hover .overlay1 {
        opacity: 1;

        .favoriteIcon {
          display: none;
        }
      }

      .text1 {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        background-color: var(--active-link);
        opacity: 1;
        color: var(--footer-text-active);
        border-radius: 6px;
        padding: 6px 3px;
        cursor: pointer;

        .custom-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          font-size: 12px;
          width: 100px;
          font-family: var(--font-family);
          cursor: pointer;
          white-space: nowrap;
          transition: all 0.3s ease;
          background-color: var(--active-link);
          opacity: 1;
          color: var(--footer-text-active);
          font-weight: bold;
          position: relative;

          img {
            margin-right: 4px;
          }
        }
      }

      .fav-icon {
        position: absolute;
        top: 10%;
        left: 10%;
        z-index: 1000;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        opacity: 1;
        border-radius: 6px;
        padding: 6px 3px;
        cursor: pointer;

        .custom-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          font-size: 12px;
          width: 100px;
          font-family: var(--font-family);
          cursor: pointer;
          white-space: nowrap;
          transition: all 0.3s ease;
          opacity: 1;
          color: var(--ion-background);
          font-weight: bold;
          position: relative;
        }
      }
    }
    &.slots-casino {
      .dc-games-ctn{
        .dc-img{
          height: 273px;

          @media screen and (max-width: 1440px) {
            height: 196px;
          }
        }
      }
    }
  }
}

.MuiAutocomplete-inputRoot,
.MuiAutocomplete-input,
.MuiAutocomplete-option,
.MuiAutocomplete-groupLabel {
  color: var(--bg-active) !important;
}

.dialog-div {
  background: none !important;

  // span,
  // div {
  //   color: var(--text-primary) !important;
  // }
  .dialog-div-body {
    background-image: var(--footer-bg-img) !important;
    background: var(--ion-background-color);
    background-size: cover;
    background-repeat: no-repeat;
    width: 300px;

    .go-corner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 32px;
      height: 32px;
      overflow: hidden;
      top: 0;
      right: 0;
      background-color: var(--heading-color);
      border-radius: 0 4px 0 32px;
      cursor: pointer;

      .go-arrow {
        margin-top: -4px;
        margin-right: -4px;
        color: var(--footer-text-active);

        .MuiSvgIcon-root {
          color: var(--footer-text-active);
        }
      }
    }

    .dialog-title {
      justify-content: center;
      color: var(--text-primary) !important;
      font-weight: bold;
      text-align: center;
      padding: 15px 15px;
      font-size: large;

      .MuiSvgIcon-root {
        opacity: 0;
      }
    }

    .dialog-body {
      color: var(--text-primary);
      text-align: center;
      padding: 20px 20px;

      .dialog-ex {
        text-align: center;
        padding: 10px 10px;
        color: var(--text-primary);
      }
    }

    .dialog-continue {
      text-align: center;
      padding: 20px 20px;

      .cancel-btn {
        width: 35%;
        height: 35px;
        border-radius: 5px;
        padding: 10px 25px;
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        cursor: pointer;
        white-space: nowrap;
        transition: all 0.3s ease;
        background-color: var(--headerbg);
        border: 2px solid var(--border-color);

        .MuiButton-label {
          color: #fff !important;
          font-weight: bold;
          background-color: var(--headerbg) !important;
        }
      }

      .btn {
        margin-left: 4px;
        margin-right: 5px;
        width: 35%;
        height: 35px;
        border-radius: 5px;
        padding: 10px 25px;
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        cursor: pointer;
        white-space: nowrap;
        transition: all 0.3s ease;
        background-color: var(--button);
        border: 2px solid var(--border-color);

        .MuiButton-label {
          color: var(--footer-text-active) !important;
          font-weight: bold;
        }
      }
    }
  }
}