.bank-tansaction-ctn {
  .MuiTableCell-head {
    white-space: nowrap;
  }
}

.transaction-stmt-ctn {
  .content-ctn {
    @media screen and (max-width: 920px) {
      margin-top: 0;
    }
  }
  .no-data {
    background-color: var(--ion-background-color);
    color: var(--text);
    text-align: center;
  }
  .header-ctn {
    margin: 0 20px;

    .label, .date-label {
      font-size: x-small;
      color: var(--text);
    }

    .transaction-menu-card {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .payment-option-btns {
        position: relative;
        top: 20px;

        .payment-btn {
          margin-left: 12px;
        }
      }
    }
    .transaction-menu-card-content {
      display: flex;
      .txn-menu-card-content-div {
        margin-right: 20px;
      }

      .from-date-filter {
        position: relative;
        top: 8px;
      }

      .to-date-filter {
        position: relative;
        top: 8px;
      }
    }
    color: white;
      ion-select {
        margin-top: 0.1rem;
        min-width: 180px;
        color: rgba(255, 255, 255, 1);
        background-color: #3B3B3B;
        border: 0;
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        height: 35px;  
        @media screen and (max-width: 720px) {
          min-width: 100%;
        }  
      }

      .payment-btn {
        background-color: var(--button);
        .MuiButton-label {
          background-color: unset !important;
          font-family: 'Montserrat';
          font-weight: 600;
        }
      }

      .MuiFormControl-root {
        margin-top: 1px;
        border: 0px !important;
        background-color: #3B3B3B !important;
        color: rgba(255, 255, 255, 0.6);
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        width: 100%;

        .MuiInputBase-root {
          height: 35px;
          width: 100%;
          border: 0;
          color: white;

          .MuiInputBase-input {
            color: #fff;
          }
        }
      }
  }
}

.transaction-filters-row {
  color: white;

  .filter-col {
    display: grid;
    grid-template-columns: auto auto;
    gap: 7px;
  }
  .label, .filter-label {
    font-size: x-small;
    color: var(--text);
  }
      ion-select {
        margin-top: 0.1rem;
        min-width: 180px;
        color: rgba(255, 255, 255, 0.6);
        background-color: #3B3B3B;
        border: 0;
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        height: 35px; 
        
        @media screen and (max-width: 720px) {
          min-width: 100%;
        }
      }

      .payment-btn {
        background-color: var(--button);
        .MuiButton-label {
          background-color: unset !important;
        }
      }

      .MuiFormControl-root {
        margin-top: 1px;
        border: 0px !important;
        background-color: #3B3B3B !important;
        color: rgba(255, 255, 255, 0.6);
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        width: 100%;

        .MuiInputBase-root {
          height: 35px;
          width: 100%;
          border: 0;
          color: white;
        }
      }
}