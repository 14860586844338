.contacts-tab-ctn {
    margin-top: 16px;
    .input-row {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 20px;
      max-width: fit-content;
      .input-label {
        color: var(--signup-font-color);
        font-size: 16px;
        margin-bottom: 5px;
      }

      .MuiFormControl-root {
        width: 300px;
        margin-left: 10px;
        font-size: 12px;
        color: #fff !important;
        .MuiFormHelperText-contained {
          color: crimson;
        }
      }
      .MuiInputBase-root {
        font-size: 12px !important;
        color: var(--input-text-color) !important;
        border: 0px;
      }
      .MuiInputBase-root .MuiInputBase-input,
      .MuiInputBase-root .MuiOutlinedInput-input {
        padding: 16px 16px;
        font-size: 12px !important;
        background-color: #eaeef3;
        border: 1px solid #eaeef3;
      }
      .MuiOutlinedInput-adornedEnd {
        background-color: var(--ion-color-light) !important;
      }

      // .input-col {
      .update-row {
        display: flex;
        align-items: center;
        .update-phone-btn {
          margin-left: 10px;
        }
        .copy-link {
          font-weight: 600;
          text-transform: capitalize;
        }
        .referral-code-field {
          border-radius: 10px;
        
          cursor: pointer;
          padding: 2px 10px;
          font-weight: 600;
          font-size: 24px;
          margin-left: 10px;
          background-color: white;
    color: black;

          @media screen and (max-width: 600px) {
            padding: 2px 10px;
            font-weight: 600;
            margin-left: 2px;
            font-size: 18px;
          }
        }
        .referral-code-copy-btn {
          background-color: var(--ion-color-primary);
          border-radius: 4px;
        }
        .share-icon {
          font-size: 24px;
        }
        .react-tel-input {
          height: 48px;
          width: 300px;
          margin-left: 10px;
          background-color: #eaeef3;
          border: 1px solid #eaeef3;
          border-radius: 6px;
          font-size: 12px;
          color: var(--input-text-color) !important;
        }
        .react-tel-input .form-control {
          background-color: var(--ion-color-light);
          border: 1px solid var(--ion-color-light);
          border-radius: 6px;
          font-size: 12px;
          color: var(--input-text-color) !important;
          height: 48px;
          &:disabled {
            opacity: 0.65;
          }
        }
        .react-tel-input .country-list .country-name {
          color: var(--ion-color-dark);
        }
        .react-tel-input .flag-dropdown {
          background-color: var(--ion-color-light);
          border: 1px solid var(--ion-color-light);
          border-radius: 3px;
          font-size: 12px;
          height: 48px;
        }
      }

      .input-box {
        border: 0;
        background-color: #eee;
        border-radius: 10px;
        padding: 14px;
        // min-width: 100%;

        &:disabled {
          opacity: 0.5;
        }

        &.MuiFormControl-root {
          padding: 0;
          .MuiInputBase-root {
            &::before {
              border-bottom: 0;
            }
          }
          // }
        }
      }
    }

    .btn-section {
      .btn {
        margin-top: 10px;
      }
    }
    .link {
      text-decoration: underline;
      color: var(--ion-color-primary);
      float: right;
      margin-left: auto;
      cursor: pointer;
    }
  }
  .contacts-tab-ctn {
    .referral-card-bg {
      background-color:white;
      height: 140px;
      border-radius: 10px;
      @media screen and (max-width: 720px) {
        height: 150px;
      }
      .referral-heading {
        padding: 20px;
        font-family: var(--headers-font-family);
        font-size: 22px;
        font-weight: 600;
        line-height: 35px;
        letter-spacing: 0em;
        text-align: left;
        color:black;
        @media screen and (max-width: 720px) {
          padding: 10px 0px 10px 10px;
          font-size: 18px;
        }
      }
      .referral-content {
        padding-left: 20px;
        color: rgba(255, 255, 255, 0.6);
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        color: black;
        @media screen and (max-width: 720px) {
          padding: 0px 0px 10px 10px;
          font-size: 12px;
        }
      }
    }
    .summary-cards-row {
      display: flex;
      flex-direction: row;

      .summary-cards {
        margin: 10px 0px 0px 10px;
        background: #fff;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
        width: 260px;
        height: 90px;
        flex-shrink: 0;
        @media screen and (max-width: 720px) {
          width: 110px;
        }
        .summary-cards-header {
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--ion-color-primary);
          height: 40px;
          flex-shrink: 0;
          color: rgba(0, 0, 0, 0.6);
          font-family: var(--headers-font-family);
          font-size: 16px;
          font-style: normal;
          font-weight: bold;
          line-height: normal;
          text-transform: capitalize;
          @media screen and (max-width: 720px) {
            font-size: 12px;
          }
        }
        .summary-cards-content {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          color: black;
        }
      }
    }

    .referral-sub-section {
      padding: 0px 10px;
      .sub-header {
        margin: 12px 0 12px 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
      }
    }
    .referral-table {
      padding: 0px 10px;
      .referral-label {
        margin: 12px 0 12px 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        color: var(--signup-font-color);
      }
      .MuiTableHead-root {
        border-radius: 8px 8px 0px 0px;
       
        background: white;
        .MuiTableCell-head {
          font-weight: 500;
          line-height: 1.5rem;
          color: black;
          @media screen and (max-width: 720px) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .table-body-td{
    background-color: #DFE1E5;
  }
  .no-bets-msg{
    color: #000;
  }
  .reports-ctn my-wallet-ctn{
    border-radius: 20px;
  }