.payment-ctn {
  padding: 0px;
  .MuiInput-root {
    border: 0px !important;
  }
  .header-ctn {
    ion-row {
      justify-content: flex-start !important;
    }
  }
  .MuiTabs-flexContainer {
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: center;
    height: 70px;
    margin-top: 10px;
    .MuiTab-root {
      min-height: 60px;
      max-height: 60px;
      text-decoration: none;
      align-items: center;
      // border-right: 2px solid var(--border-color);
      background-color: var(--ion-secondary);
      margin-left: 5px;
      margin-right: 5px;
      border-radius: 4px;
      opacity: 1;
      .MuiTab-wrapper {
        font-style: normal;
        font-weight: bold;
        font-size: 13px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: var(--text-primary);
        text-transform: uppercase;
        text-align: center;

        img {
          height: 80px;
          width: 80px;
        }
      }
      &.Mui-selected {
        opacity: 1;
        background: var(--bg-active) !important;
        border-right: 0px !important;
        border-left: 0px !important;
        .MuiTab-wrapper {
          color: var(--text-common-dark);
          font-weight: bold;
          border-right: 0px !important;
          border-left: 0px !important;
          img {
            height: 80px;
            width: 80px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .payment-ctn {
    .MuiTabs-flexContainer {
      display: flex;
      justify-content: start;
      width: 100%;
      align-items: center;
      height: 50px;
      margin-top: 10px;
      .MuiTab-root {
        min-height: 45px;
        max-height: 45px;
        text-decoration: none;
        align-items: center;
        background-color: var(--ion-secondary);
        margin-left: 3px;
        margin-right: 3px;
        border-radius: 4px;
        opacity: 1;
        .MuiTab-wrapper {
          font-style: normal;
          font-weight: bold;
          font-size: 12px !important;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: var(--text-primary);
          text-transform: uppercase;
          text-align: center;
          img {
            height: 64px;
            width: 64px;
          }
        }
        &.Mui-selected {
          opacity: 1;
          background: var(--bg-active) !important;
          border-right: 0px !important;
          border-left: 0px !important;
          .MuiTab-wrapper {
            color: var(--text-common-dark) !important;
            font-weight: bold;
            border-right: 0px !important;
            border-left: 0px !important;
            img {
              height: 50px;
              width: 50px;
            }
          }
        }
      }
    }
  }
}
