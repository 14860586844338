.my-bets-ctn {
  background-color: var(--background-image-color);
  margin-top: 58px;
  border-radius: 25px;
  padding-bottom: 50px;
  position: relative;

  &.payment-withdraw-ctn {
    @media screen and (min-width: 720px) {
      background-color: unset;
      margin-top: 0;
      border-radius: 0;
      padding-bottom: 0;
      &::before {
        content: '';
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 1;
        background-image: unset;
        border-top-left-radius: 35px;
        border-top-right-radius: 35px;
      }
    }
  }

  .title {
    color: white;
  }

  .title-border-line {
    height: 1px;
    width: 100%;
    background: var(--background-gradient);
    position: relative;
    top: -12px;
    @media screen and (max-width: 720px) {
      top: -18px;
    }
  }

  .sports-filters-row {
    .MuiButton-root {
      background: var(--button);
      margin-right: 6px;
    }
  }

  .MuiTableCell-root {
    color: #000;
  }
}

.reports-ctn .filters-row {
  width: 100% !important;
}

.my-bets-ctn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: var(--background-image-opacity);
  background-image: url('../../assets/images/home/homeview/background.svg');
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}

@media screen and (max-width: 720px) {
  .my-bets-ctn {
    .game-label {
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .col-data-desc {
      font-size: 12px;
    }

    .sports-filters-row {
      .MuiButton-root {
        margin-right: 2px;
      }
    }
  }
}
