// .user-pl-stmt-ctn {
//   .pl-header-ctn {
//     color: var(--ion-text-light);
//     display: flex;
//     justify-content: space-between;

//     .filters-row {
//       .trans-type-filter {
//         color: #000;
//         min-width: 192px;
//         margin-top: 4px;
//       }
//     }
//   }

//   .user-pl-filters-row {
//     display: flex;
//     margin-left: 8px;
//     div {
//       margin-right: 8px;
//     }
//     .from-date-filter,
//     .to-date-filter {
//       width: 200px;
//     }
//     .MuiInputBase-root {
//       border-radius: 6px;
//       height: 46px;
//       padding-left: 4px;
//       padding-right: 4px;
//       color: var(--ion-text-color-contrast);
//     }
//     .MuiFormControl-marginNormal {
//       margin-top: 4px;
//     }
//     .user-filter {
//       padding-top: 14px;
//       .MuiInputLabel-animated {
//         color: var(--ion-text-color-contrast);
//       }
//     }
//     .MuiSvgIcon-root {
//       color: var(--ion-text-color-contrast);
//     }
//   }

//   .mob-view {
//     display: none;
//   }
// }

// @media (min-width: 768px) {
//   .user-pl-filters-row {
//     justify-content: flex-end;
//     .user-filter {
//       padding-top: 14px;
//       .users-form-control {
//         width: 214px;
//       }
//     }
//   }
// }

// @media only screen and (min-width: 340px) and (max-width: 721px) {
//   .user-pl-stmt-ctn {
//     .filters-row {
//       .date-label {
//         font-size: 12px;
//       }

//       .MuiInputBase-root {
//         height: 32px !important;
//         input {
//           font-size: 14px !important;
//         }
//       }

//       .from-date-filter,
//       .to-date-filter {
//         max-width: 180px;
//       }
//     }

//     .game-filters {
//       margin: 10px 2px 0 10px !important;
//       .game-toggle .toogle-name {
//         font-size: 16px !important;
//       }
//     }

//     .mob-view {
//       display: inline-flex !important;
//       justify-content: center;
//     }

//     .web-view {
//       display: none !important;
//     }

//     .page-title {
//       margin-top: 0 !important;
//     }
//   }
// }

// Fix iphone-X device
@media only screen and (max-width: 398px) {
  .user-pl-stmt-ctn {
    .page-title {
      font-size: 24px !important;
    }

    .user-pl-stmt-ctn {
      .game-filters .game-toggle .toogle-name {
        font-size: 12px !important;
      }
    }
  }
}

.reports-ctn-pl {
  background-color: var(--background-image-color);
  margin-top: 58px;
  border-radius: 45px 45px 10px 10px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;

  @media screen and (max-width: 720px) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 11px;
    padding-right: 11px;
    margin-top: 24px;
  }

  .header-ctn {
    position: relative;

    .filters-row {
      .date-filter.mr-11 {
        @media screen and (max-width: 720px) {
          margin-right: 11px;
        }
      }
    }

    .title-border-line {
      height: 1px;
      width: 100%;
      background: var(--background-gradient);
      position: absolute;
      top: 32px;

      @media screen and (max-width: 720px) {
        top: 24px;
      }
    }
  }
}

.reports-ctn-pl::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: var(--background-image-opacity);
  background-image: url('../../assets/images/home/homeview/background.svg');
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}


@media screen and (max-width: 720px) {
  // .user-pl-stmt-ctn {
  //   .filters-row {
  //     justify-content: space-between !important;
  //     padding: 0 6px;
  //     .MuiFormControl-root {
  //       max-width: 180px !important;
  //       margin: 0;
  //     }
  //   }

  .pl-mob-div {
    margin-top: 20px;

    .MuiCollapse-container {
      background-color: #ece8e8;
    }

    div {
      font-family: 'Montserrat';
      color: #000;
    }

    .b-text {
      font-size: 12px;
    }

    .heading {
      display: flex;
      justify-content: space-between;
      background: var(--ion-color-primary);
      align-items: center;
      height: 30px;
      padding: 0 4px;
      background-color: #CF8D2E;
      font-size: 15px;
      position: relative;

      .pl {
        display: flex;
      }

      .loss,
      .profit {
        font-weight: 600;
      }

      .date {
        display: flex;
        align-items: center;
      }
    }

    .pl-content {
      position: relative;
      background-color: #ece8e8;

      ion-row {
        font-size: 12px;
      }

      .bet-into {
        .MuiButtonBase-root {
          color: #000 !important;
        }

        background-color: #ece8e8;
        margin-bottom: 4px;
      }
    }

    .pl-summary-mob {
      padding: 4px;
      background-color: #ece8e8;
      border: 1px solid black;
      margin: 0 5px 2px 5px;
      position: relative;

      div,
      ion-row {
        font-size: 12px;
      }
    }
  }

  // }
}