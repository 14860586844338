.reset-pwd-form-ctn {
  height: 100%;
  display: flex;
  flex-direction: column;

  .user-details {
    color: var(--ion-text-light);
    margin-bottom: 15px;
    margin-top: 15px;
    .title {
      font-size: 16px;
    }
    .note {
      font-size: 14px;
    }
  }

  .pwd-input {
    display: inline-grid;
  }

  .submit-form-btn {
    margin-top: 20px !important;
    margin-bottom: 20px;
    height: 48px;
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  .error-msg {
    color: var(--ion-color-danger);
    margin-top: 20px;
    text-align: center;
  }
  .success-msg {
    color: var(--ion-color-success);
    margin-top: 20px;
    text-align: center;
  }
  .pwd-input {
    margin-top: 40px;
  }

  .pwd-field {
    max-width: unset;
    height: 48px;
  }

  .MuiInputBase-root {
    color: #ffffff;
    height: 48px;
  }

  .input-tooltip {
    color: var(--ion-text-color);
    font-size: 20px;
    padding-left: 4px;
  }
}
