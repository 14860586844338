.user-report-ctn {
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 1%;
  .header {
    display: flex;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 2%;
    .title {
      color: var(--text);
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
  .side-nav-ctn {
    background-color: var(--ion-secondary);
    min-height: 500px;
    border-radius: 15px;
    .profile-ctn {
      padding: 25px;
      display: flex;
      align-items: center;
      height: 150px;
      .profile {
        height: 90px;
        width: 90px;
      }
      .name-ctn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        color: var(--text);
        margin-left: 10%;
        .small-text {
          font-size: 12px;
          text-align: left;
          font-weight: 400;
        }
        .name-text {
          font-size: 24px;
          text-align: left;
          color: var(--text) !important;
          font-weight: 600;
          text-transform: capitalize;
        }
      }
    }
    .side-nav-content {
      background-color: var(--headerbg);
      margin-top: 10px;
      border-radius: 0px 100px 15px 15px;
      height: 400px;
      .list {
        padding-top: 30px;
        .list-item {
          display: flex;
          justify-content: space-around;
          flex-direction: row;
          align-items: center;
          height: 80px;
          border-bottom: 0.954217px solid #0d411f;
          .navlink {
            text-decoration: none;
          }
          .lable {
            color: var(--text);
            font-size: 16px;
            font-weight: 400;
            text-decoration: none;
          }
          .icon {
            .icon-img {
              height: 30px;
            }
          }
          .icon1 {
            margin-right: 15px;
          }
        }
      }
      .bottom-section-ctn {
        position: absolute;
        bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        color: var(--text);
        width: 90%;
        a,
        .navlink {
          text-decoration: none;
          color: var(--text) !important;
          .label {
            color: var(--text) !important;
          }
        }
      }
    }
  }
  .card {
    height: 150px;
    border-radius: 15px;
    background-color: var(--ion-secondary);
    padding: 20px;
    margin-bottom: 10px;
    .icon-ctn {
      display: flex;
      justify-content: flex-end;
    }
    .balance-ctn {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      color: var(--text);
      .label {
        font-size: 12px;
        font-weight: 400;
      }
      .value {
        font-size: 40px;
        font-weight: 500;
      }
    }
  }
}
