.info-ctn {
  padding: 5%;
  padding-bottom: 10%;
  h4 {
    color: #fff;
  }
  .MuiTypography-body1 {
    color: #90949d;
    font-size: 13px;
    padding: 5px;
    h6 {
      color: var(--ion-color-primary);
      list-style-type: circle;
      padding: 5px;
      color: #fff;
    }
  }
}
.MuiTypography-body1 {
  color: #90949d;
  font-size: 13px;
  padding: 5px;
  h6 {
    color: var(--ion-color-primary);
    list-style-type: circle;
    padding: 5px;
    color: #fff;
  }
}