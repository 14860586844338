.mfa-ctn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;

  .mfa-msg {
    margin-top: 30px;
    width: 100%;
    text-align: center;
    color: var(--ion-text-color);
  }

  .mfa-err-msg {
    width: 100%;
    text-align: center;
    color: var(--ion-text-color);

    .deactivate-link {
      color: var(--ion-color-primary);
      cursor: pointer;
    }
  }

  .scanned-btn {
    margin-top: 30px;
  }

  .mfa-spinner {
    --color: var(--ion-text-color);
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  //CSS
  .mfa-ctn {
    justify-content: space-around;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  //CSS
  .mfa-ctn {
    justify-content: space-around;
  }
}
