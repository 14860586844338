.my-profile-ctn {
  display: flex;
  flex-direction: row;

  .left-ctn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;

    .pro-ctn {
      display: flex;
      flex-direction: column;

      .name {
        font-size: 18px;
        text-transform: capitalize;
        text-align: center;
        margin-top: 10px;
      }
    }

    @media screen and (max-width: 720px) {
      display: none;
    }
  }

  .right-ctn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;

    @media screen and (max-width: 720px) {
      width: 100%
    }
  }
}

.login-form-ctn {
  padding-top: 15px;
  padding-bottom: 15px;

  .login-col {
    width: 100%;
    margin-top: 9.8px;
  }

  .country-col {
    margin-top: 10px;
  }

  .native-input.sc-ion-input-md {
    background-color: var(--ion-background) !important;
    border-bottom: 3px solid var(--headerbg) !important;
    border: 0px;
    color: var(--text) !important;
    border-radius: 0px !important;
  }

  input {
    background: var(--form-input-background) !important;
    color: var(--text) !important;
    border-radius: 6px;
    border: 1px solid #7C8288;
  }

  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: var(--sub-header-btn-text) !important;
    border-radius: 6px;
  }

  ::placeholder {
    font-size: 16px;
    color: var(--text) !important;
    padding: 2px;
    font-family: var(--font-family);
    opacity: 1;
  }

  .login-form-btn {
    margin-top: 1.5rem;
    height: 55px;
    width: 100%;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 19px;
    background-color: var(--active-link);
    color: var(--text-common-dark) !important;
    border-radius: 6px;
    border: none;

    @media screen and (max-width: 720px) {
      height: 44px;
    }

    .MuiIconButton-label {

      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: #FFFFFF;

      @media screen and (max-width: 720px) {
        font-size: 18px !important;
      }

      svg {
        display: none;
        fill: var(--text-common-dark) !important;
        color: var(--text-common-dark) !important;
      }
    }

    .MuiButton-label {
      color: #fff !important;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.02em;
    }
  }

  .login-err-msg {
    color: var(--text) !important;
  }
}
.profile-col-align {
  margin-top: 30px;
}
.profile-form-align {
  display: flex;
  flex-direction: column;
  margin-top: -25px;
}
.my-profile-ctn-footer {
  margin-top: 50px;
}
.change-pwd-form-ctn {
  margin-left: 10px;
  width: 95%;
}
.change-pwd-btn{
  color: #000;
}
.game-rules-drawer {
  .MuiDrawer-paper {
    @media screen and (max-width: 720px) {
      min-width: 100%;
    }
  }
}