.competitions-menu {
    background-color: var(--home-main-blocck-bg);
    border-radius: 24px;
    font-family: var(--headers-font-family);
    .username-ctn {
      display: flex;
      flex-direction: column;
      color: var(--ion-text-light);
      padding: 10px;
  
      .username {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-family: var(--headers-font-family);
        .username-span {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          @media only screen and (max-width: 1440px) {
            font-size: 13px;
          }
        }
        .user-avatar {
          height: 40.11px;
          width: 40.11px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 6.7px;
          @media only screen and (max-width: 1440px) {
            width: 30px;
            height: 30px;
          }
        }
        .deposit-btn {
          background: var(--profile-bot-color);
          border-radius: 50px;
          color: #fff;
          padding: 5px 10px;
          font-size: 12px;
          margin-left: 10px;
          @media only screen and (max-width: 1440px) {
            padding: 3px 5px;
          }
        }
      }
    }
    .border-bg {
      background: #4d5b75;
      margin: 10px;
    }
    .balence-block {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      padding-top: 20px;
      @media only screen and (max-width: 1440px) {
        font-size: 12px;
      }
    }
    .MuiTypography-body1 {
      color: black;
      text-transform: capitalize !important;
      font-size: 13px;
    }
    .competitions-menu-card {
      margin-inline-start: 0px;
      width: initial;
      margin-top: 0px;
      border: 0px !important;
      .competitions-menu-card-header {
        color: var(--teble-head);
        font-size: 16px;
      }
      .competitions-menu-card-content {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: var(--headers-font-family);
        .MuiButtonBase-root,
        .MuiListItem-root {
          .MuiListItemIcon-root {
            min-width: 35px !important;
          }
        }
        padding-inline-start: 0px;
        padding-inline-end: 0px;
        min-height: 75vh;
        overflow-y: auto;
        padding: 0px;
  
        .MuiSvgIcon-root,
        .expand-icon {
          color: var(--text-primary);
          fill: var(--text-primary);
        }
        .eventTypes-menu-tabs,
        .competitions-menu-tabs {
          .eventTypes-menu-nav-link,
          .competitions-menu-nav-link {
            text-decoration: none;
            color: var(--teble-head);
            .MuiButtonBase-root,
            .MuiListItem-root {
              .MuiListItemIcon-root {
                min-width: 35px !important;
              }
            }
            .eventTypes-menu-tab,
            .competitions-menu-tab {
              opacity: 1;
              min-width: 40px;
              max-width: 220px;
              text-align: left;
              text-transform: none;
              font-style: normal;
              letter-spacing: 0.01em;
            }
  
            .competitions-menu-tab {
              min-height: 26px;
              padding: 10px 18px;
              font-size: 12px;
              line-height: 15px;
            }
          }
        }
        .logout-btn {
          background: green;
        }
        .eventTypes-menu-tab-divider {
          width: 80%;
          margin-left: auto;
          margin-right: auto;
        }
        .competitions-menu-tabs {
          max-height: 40vh;
          overflow-y: auto;
        }
        .competitions-menu-msg-text {
          font-size: 16px;
          color: var(--teble-head);
          padding: 16px;
        }
      }
    }
    .mob-competitions-view {
      display: none;
    }
  
    .search-events-ctn {
      .search-filter {
        width: 210px;
        margin-right: auto;
        margin-bottom: 8px;
      }
    }
    .card-content-md {
      @media screen and (max-width: 1366px) {
        padding: 0;
      }
    }
  }
  
  @media (max-width: 1120px) {
    .competitions-menu {
      width: 100%;
      margin: 0;
    }
  }
  .competitions-menu {
    .competitions-menu-card-content {
      background: var(--side-menu);
      border-radius: 8px 8px 8px 8px !important;
      min-height: 83vh;
      overflow-y: auto;
      padding: 6px;
      @media screen and (max-width: 720px) {
        padding: 4px;
      }
    }
  
    .MuiListItemText-inset {
      padding-left: 0%;
      color: #fff !important;
    }
    .eventTypes-menu-nav-link {
      text-decoration: none;
  
      .MuiListItem-gutters {
        padding-left: 4px !important;
        padding-right: 4px !important;
        background: var(--ion-color-base);
        border-radius: 8px;
        margin-bottom: 10px;
      }
    }
  
    .MuiListItem-button {
      width: 100%;
      text-decoration: none;
      text-transform: capitalize !important;
      &.logout-btn {
        background: var(--odd-btn-animation-color);
        border-radius: 50px;
        margin-top: 20px;
        text-align: center;
        .MuiTypography-body1 {
          text-align: center;
          justify-content: center;
        }
      }
      .MuiListItem-gutters {
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
      .icon-ctn {
        background-color: var(--card);
        border: 1px solid var(--filter-border1);
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        height: 38px;
        width: 38px;
        border-radius: 8px;
        margin-right: 10px;
        .sport-icon0 {
          height: 20px;
          width: 20px;
        }
        .sport-icon2 {
          height: 22px;
          width: 22px;
        }
  
        .sport-icon5,
        .sport-icon6,
        .sport-icon7,
        .sport-icon3,
        .sport-icon4 {
          height: 24px;
          width: 24px;
        }
  
        .sport-icon1 {
          height: 26px;
          width: 26px;
        }
        .sport-icon {
          height: 24px;
          width: 24px;
        }
      }
      .expand-icon {
        color: var(--teble-head) !important;
        fill: var(--teble-head);
      }
      .MuiTypography-body1 {
        line-height: normal;
        display: flex;
        align-items: center;
        color: var(--ion-text-light) !important;
        font-family: var(--headers-font-family);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 10px;
      }
    }
    .active {
      background-color: var(--ion-background) !important;
      .MuiListItem-button {
        background-color: var(--ion-background) !important;
        border-radius: 8px 8px 8px 8px !important;
        border: 1px solid var(--ion-color-success-shade);
        .icon-ctn {
          background-color: var(--common-dark) !important;
        }
        .expand-icon {
          color: var(--teble-head) !important;
          fill: var(--teble-head);
        }
      }
      .MuiTypography-body1 {
        color: var(--ion-text-white) !important;
      }
      // img {
      //   filter: var(--game-category-icons);
      // }
    }
    .Collapse-list {
      background-color: var(--ion-background) !important;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      margin-top: -10px;
      .active {
        .MuiTypography-body1 {
          color: var(--ion-color-primary);
        }
      }
      .no-competitions {
        background: var(--side-menu);
      }
    }
  }
  .competitions-menu-card-content {
    .eventTypes-menu-nav-link .MuiButtonBase-root,
    .MuiListItem-root {
      .MuiListItemIcon-root {
        min-width: 40px !important;
      }
    }
  }
  .username-span {
    color: gray;
  }